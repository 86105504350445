export const chartBackgroundColorsArray = [
  'rgba(0, 0, 204, 0.8)',
  'rgba(255,193,7, 1)',
  'rgba(8, 41, 138, 0.8)',
  'rgba(44, 160, 28, 0.8)',
  'rgba(51, 204, 255, 0.8)',
  'rgba(165, 216, 221, 0.8)',
  'rgba(0, 145, 213, 0.8)',
  'rgba(72, 72, 72, 0.8)',
  'rgba(219, 174, 88, 0.8)',
]
