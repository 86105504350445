<script>
import { Doughnut, mixins } from 'vue-chartjs'
import ChartDataLabels from 'chartjs-plugin-datalabels'

export default {
  extends: Doughnut,
  mixins: [mixins.reactiveProp],
  plugins: [ChartDataLabels],
  props: ['options'],
  mounted () {
    // Overwriting base render method with actual data.
    this.renderChart(this.chartData, this.options)
  }
}
</script>
