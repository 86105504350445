<template>
  <div class="ManagerTasksDashboard">

    <!-- SET COLLABORATEUR ALERT -->
    <CModal
      :show.sync="isManagerAlertCreating"
      :no-close-on-backdrop="true"
      title="Lever une alert sur un collaborateur"
      size="lg"
      color="dark"
    >
      <CForm>

        <CSelect
          :value.sync="managerAlertCollaborateur"
          label="Collaborateur"
          placeholder='Filtrer par collaborateur'
          :horizontal='true'
          :options='managerAlertCollaborateurSelectOptions'
          custom
        />

        <CTextarea
          label="Message d'alerte à laisser à votre collaborateur"
          v-model="managerAlertMessage" type="text"
          rows="3"
          maxlength="300"
          placeholder="Description"
          @input="$v.managerAlertMessage.$touch()"
          :isValid="$v.managerAlertMessage.$dirty ? !$v.managerAlertMessage.$error : null"
        >
        </CTextarea>


        <CRow class="mt-4">
          <CCol class="text-center">
            <CButton
              color="outline-dark"
              shape="pill" block class="px-4"
              :disabled="$v.managerAlertCollaborateur.$invalid || $v.managerAlertMessage.$invalid"
              @click='setCollaborateurManagerAlert'>
              Enregistrer votre message d'alerte
            </CButton>
          </CCol>
        </CRow>
      </CForm>

      <template #footer>
        <CButton @click="isManagerAlertCreating = false" color="dark">Annuler</CButton>
      </template>
    </CModal>

    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      color="black">
    </loading>

    <CRow>
      <CCol>
        <h1> Reporting Manager </h1>
      </CCol>
    </CRow>

    <CCard class="mt-3">
      <CCardBody>
        <CRow>
          <CCol xl="5">
            <CInput
              label="Date de début"
              type="date"
              :horizontal='true'
              v-model="startDateFilter">
            </CInput>
          </CCol>
          <CCol xl="5">
            <CInput
              label="Date de fin"
              type="date"
              :horizontal='true'
              v-model="endDateFilter">
            </CInput>
          </CCol>
          <CCol xl="2">
            <CButton
              color="primary"
              variant="outline"
              shape="pill"
              block
              @click="getAllTasksForManager()">
            Filtrer par date
          </CButton>
          </CCol>
        </CRow>

        <CRow class="mt-3 mt-xl-0">
          <CCol>
            <a class="eclerk-link" href="#" @click="reinitializeWeek">
              Depuis le début de la semaine
            </a>
            <br class="d-sm-block d-md-none">
            <a class="ml-0 ml-md-2 eclerk-link" href="#" @click="reinitializeMonth">
              Depuis le début du mois
            </a>
          </CCol>
        </CRow>

        <CRow class="mt-3">
          <CCol xl="3">
            <vue-bootstrap-typeahead
              ref="clientFilterAutocomplete"
              v-model="clientFilterName"
              :data="filterClients"
              :serializer="item => item.name"
              @hit="clientFilterObject = $event"
               placeholder="Filter par nom du client"
            />

          </CCol>
          <CCol xl="4" class="mt-3 mt-xl-0">
            <CSelect
              :value.sync="missionFilter"
              label="Mission"
              placeholder='Filtrer par mission'
              :horizontal='true'
              :options='missionsFilterSelectOptions'
              v-on:change="getAllTasksForManager()"
              custom
            />
          </CCol>
          <CCol xl="5">
            <CSelect
              :value.sync="collaborateurFilter"
              label="Collaborateur"
              placeholder='Filtrer par collaborateur'
              :horizontal='true'
              :options='collaborateursSelectOptions'
              v-on:change="getAllTasksForManager()"
              custom
            />
          </CCol>
        </CRow>

      </CCardBody>
    </CCard>

    <CRow>
      <CCol sm="4">
        <CWidgetIcon
          text="jours de travail"
          color="primary"
          :icon-padding="false"
        >
          <strong>{{(totalWorkingHours/8).toFixed(1)}}</strong>
        </CWidgetIcon>
      </CCol>
      <CCol sm="4">
        <CWidgetIcon
          text="heures de travail"
          color="warning"
          :icon-padding="false"
        >
          <strong>{{totalWorkingHours}}</strong>
        </CWidgetIcon>
      </CCol>
      <CCol sm="4">
        <CWidgetIcon
          text="missions"
          color="primary"
          :icon-padding="false"
        >
          <strong>{{totalNumberMissions}}</strong>
        </CWidgetIcon>
      </CCol>
    </CRow>

    <CRow v-if="missionFilter == 'false'">
      <CCol lg="6">
        <CCard >
          <CCardHeader>
            <strong>Missions - Vision consolidée</strong>
          </CCardHeader>
          <CCardBody>
            <doughnutChart
              :chart-data="{labels: tasksDoghnutRenderMission, datasets: tasksDoghnutRenderTime}"
              :options="doughnutChartOptions"
            />
          </CCardBody>
        </CCard>
      </CCol>
      <CCol lg="6">
        <CCard >
          <CCardHeader>
            <strong>Missions - Vision détaillée</strong>
          </CCardHeader>
          <CCardBody>
            <barChart
              :chart-data="{labels: tasksRenderDates, datasets: tasksBarChartMissionsRenderTime}"
              :options="missionBarChartOptions">
            </barChart>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <CRow v-if="collaborateurFilter == 'false'">
      <CCol lg="6">
        <CCard>
          <CCardHeader>
            <strong>Collaborateurs - Vision consolidée</strong>
          </CCardHeader>
          <CCardBody>
            <barChart
              :chart-data="{labels: tasksRenderCollaborateurs, datasets: tasksBarChartCollaborateursRenderTime}"
              :options="collaborateurBarChartOptions">
            </barChart>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol lg="6">
        <CCard>
          <CCardHeader>
            <strong>Collaborateurs - Vision détaillée</strong>
          </CCardHeader>
          <CCardBody>
            <lineChart
              :chart-data="{labels: tasksRenderDates, datasets: tasksLineChartCollaborateursRenderTime}"
              :options="collaborateurLineChartOptions">
            </lineChart>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <CCard>
      <CCardBody>

        <CRow class="m-4">
          <CCol>
            <strong> Vos messages d'alerte </strong>
            <CDataTable
              class="mt-2"
              headVariant="light"
              :items="collaborateursAlertMessageRender"
              :fields="collaborateursAlertMessageRenderFields"
            >
            <template #actions="{item}">
              <td>
                <CButton
                  color="dark"
                  variant="outline"
                  square
                  size="sm"
                  @click="removeCollaborateurManagerAlert(item.id)"
                >
                    Supprimer le message d'alerte
                </CButton>
              </td>
            </template>

            <template #no-items-view>
              <CRow>
                <CCol class="text-center">
                  Aucun message d'alerte enregistré
                </CCol>
              </CRow>
            </template>
            </CDataTable>
            <CButton
            color="outline-danger"
            shape="pill" block class="px-4"
            @click='isManagerAlertCreating = true'>
            Créer un message d'alerte pour un collaborateur
          </CButton>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>

    <CRow>
      <CCol>
        <CCard >
          <CCardHeader>
            <strong>Vue détaillée par tâche</strong>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol>
                <CDataTable
                  items-per-page-select
                  :items-per-page="50"
                  column-filter
                  sorter
                  pagination
                  :items="detailledTasksRender"
                  :fields="detailledTasksRenderFields"
                  :noItemsView='{ noResults: "Aucun résultat", noItems: "Aucun résultat" }'
                >
                  <template #over-table>
                    <exportTableDataComponent
                      v-bind:data="detailledTasksRender"
                      v-bind:fields="tasksFileRenderFields"
                      name="Eclerk - Taches"
                      label="Exporter la liste"
                    />
                  </template>
                  <template #mission="{item}">
                    <td>
                      <span v-if="item.hasBeenBilled">
                         {{item.mission}} <span class="text-primary"> - (facturé)</span>
                       </span>
                       <span v-else>
                         {{item.mission}}
                       </span>
                    </td>
                  </template>
                  <template #date="{item}">
                    <td>
                     <span>{{$dayjs(item.date).format('DD/MM/YYYY')}}</span>
                    </td>
                  </template>
                  <template #description="{item}">
                    <td style="white-space: pre-line;">
                     <small>{{item.description}}</small>
                    </td>
                  </template>

                </CDataTable>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import { APIUserConnected } from '@/api/APIUserConnected'
import { APIManagerConnected } from '@/api/APIManagerConnected'

import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength, integer } from 'vuelidate/lib/validators'

import renderMixins from '@/mixins/renderMixins'

import { timeOperations } from '@/mixins/utilMixins'
import { chartBackgroundColorsArray } from '@/variables/chartBackgroundColors'

import barChart from '@/components/chartComponents/barChart'
import doughnutChart from '@/components/chartComponents/doughnutChart'
import lineChart from '@/components/chartComponents/lineChart'

import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'

import Loading from 'vue-loading-overlay';

import exportTableDataComponent from '@/components/exportTableDataComponent'

import 'vue-loading-overlay/dist/vue-loading.css';

const apiUserConnected = new APIUserConnected()
const apiManagerConnected = new APIManagerConnected()


export default {
  name: 'ManagerTasksDashboard',
  components: {
    Loading,
    VueBootstrapTypeahead,
    barChart,
    doughnutChart,
    lineChart,
    exportTableDataComponent
  },
  mixins: [
    timeOperations,
    validationMixin,
    renderMixins
  ],
  data: function () {
    var today = this.$dayjs()
    var oneWeekAgo = today.subtract('1', 'weeks')
    return {
      // ------------- FILTERS -----------
      startDateFilter: oneWeekAgo.format('YYYY-MM-DD'),
      endDateFilter: today.format('YYYY-MM-DD'),
      collaborateurFilter: 'false',

      filterClients: [],
      clientFilterName: '',
      clientFilterObject: {
        id: '',
        name: ''
      },

      missionsFilterSelectOptions: [{label: 'Toutes', value: 'false'}],
      missionFilter: 'false',

      // ------------- TASKS -------------

      tasks: [],
      isAllManagerTasksDashboardLoading: false,

      // ------------- DETAILED RENDER TASK -----------
      detailledTasksRender: [],
      detailledTasksRenderFields: [
        { key: "mission", label: "Mission"},
        { key: "collaborateur", label: "Collaborateur"},
        { key: "date", label: "Date", sortable: true },
        { key: "heures", label: "Heures", filter: false, sorter: false},
        { key: "minutes", label: "Minutes", filter: false, sorter: false},
        { key: "description", label: "Description", filter: false},
      ],

      tasksFileRenderFields: {
        'Mission': 'mission',
        'Collaborateur': 'collaborateur',
        "Date": 'date',
        "Heures": 'heures',
        "Minutes": 'minutes',
        "Description": 'description',
      },

      // ------------- CLIENTS -----------
      clients: [],
      clientsSelectOptions: [],
      isAllClientsLoading: false,

      // ------------- MISSION -----------
      missions: [],
      isAllMissionsLoading: false,

      // ------------- COLLABORATEURS -----------
      collaborateurs: [],
      collaborateursSelectOptions: [],
      isAllCollaborateursLoading: false,

      // ------------ MANAGER SET COLLABORATEUR ALERT -----
      isManagerAlertCreating: false,
      managerAlertCollaborateur: '',
      managerAlertCollaborateurSelectOptions : [],
      managerAlertMessage: '',

      collaborateursAlertMessageRender: [],
      collaborateursAlertMessageRenderFields: [
        { key: "first_name", label: "Prénom", tdClass: 'ui-helper-center', sortable: true},
        { key: "last_name", label: "Nom", tdClass: 'ui-helper-center', sortable: true},
        { key: "manager_alert", label: "Message d'alerte actuel", tdClass: 'ui-helper-center' },
        { key: "actions", label: "Actions", _style: "width: 20%;"},
      ],

      // ------------- STATISTIQUES ----------------------
      totalWorkingHours: 0,
      totalNumberMissions: 0,

      // ------------- TEMPS PAR MISSION DOGNHUT ---------
      tasksDoghnutRenderMission: [],
      tasksDoghnutRenderTime: [{
        borderWidth: 2,
        data: [],
        backgroundColor: []
      }],
      doughnutChartOptions: {
        title: {
           display: true,
           text: 'Répartition du temps par mission (en heures)'
        },
        legend: {
          display: false,
        },
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          datalabels: {
            color: 'white',
            display: function (context) {
              return context.dataset.data[context.dataIndex] > 0
            },
          }
        }
      },

      // ------------ BAR CHART MISSIONS TASKS --------------
      tasksRenderDates: [],
      tasksBarChartMissionsRenderTime: [],
      missionBarChartOptions: {
        title: {
           display: true,
           text: 'Répartition journalière de vos missions (en heures)'
        },
        scales: {
          xAxes: [{
            stacked: true,
            gridLines: {
              display: false
            },
            ticks: {
              autoSkip: false
            }
          }],
          yAxes: [{
            display: false,
            stacked: true,
            gridLines: {
              display: false
            }
          }]
        },
        legend: {
          display: false,
        },
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          datalabels: {
            color: 'white',
            display: function (context) {
              return context.dataset.data[context.dataIndex] > 0
            },
          }
        }
      },

      // ------------ LINE CHART COLLABORATEURS TASKS --------------
      tasksLineChartCollaborateursRenderTime: [],
      collaborateurLineChartOptions: {
        title: {
           display: true,
           text: 'Temps travaillé par collaborateur'
        },
        scales: {
          xAxes: [{
            gridLines: {
              display: false
            },
          }],
          yAxes: [{
            gridLines: {
              display: false
            }
          }]
        },
        legend: {
          display: true,
          position: 'bottom'
        },
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          datalabels: {
            display: false
          }
        }
      },

      // ------------ BAR CHART COLLABORATEURS TASKS --------------
      tasksRenderCollaborateurs: [],
      tasksBarChartCollaborateursRenderTime: [],
      collaborateurBarChartOptions: {
        title: {
           display: true,
           text: 'Répartition de vos missions par collaborateur (en heures)'
        },
        scales: {
          xAxes: [{
            stacked: true,
            gridLines: {
              display: false
            },
            ticks: {
              autoSkip: false
            }
          }],
          yAxes: [{
            display: false,
            stacked: true,
            gridLines: {
              display: false
            }
          }]
        },
        legend: {
          display: false,
        },
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          datalabels: {
            color: 'white',
            display: function (context) {
              return context.dataset.data[context.dataIndex] > 0
            },
          }
        }
      },
    }
  },
  computed: {
    token () {
      return this.$store.state.auth.token
    },
    isLoading () {
      if (this.isAllManagerTasksDashboardLoading || this.isAllClientsLoading || this.isAllMissionsLoading || this.isAllCollaborateursLoading) {
        return true
      }
      return false
    },
  },
  validations: {
    managerAlertCollaborateur: {
      required,
      integer
    },
    managerAlertMessage: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(300)
    },
  },
  created: function() {
    this.$dayjs.locale('fr')
    if (this.$route.params.client_pk && this.$route.params.mission_pk) {

      this.isAllClientsLoading = true
      apiUserConnected.getClient(this.token, this.$route.params.client_pk)
      .then((result) => {
        this.clientFilterObject = {
          'id': result.data.id,
          'name': result.data.name
        }
        this.$refs.clientFilterAutocomplete.inputValue = result.data.name

      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isAllClientsLoading = false
      })
      this.missionFilter = this.$route.params.mission_pk
    }


    this.getAllTasksForManager()
    this.getAllCollaborateurs()
  },
  watch: {
    clientFilterName(newQuery) {
      if (newQuery == '') {
        this.filterClients = []
        this.clientFilterObject = {
          id: '',
          name: ''
        }
        this.missionsFilterSelectOptions = [{label: 'Toutes', value: 'false'}]
        this.missionFilter = 'false'
      }
      else if (newQuery.length >= 2) {
        apiUserConnected.searchClientByName(this.token, newQuery)
        .then((res) => {
          this.filterClients = res.data
        })
      }
    },

    clientFilterObject(newObject) {
      if (newObject.id === parseInt(newObject.id, 10)) {
        if (newObject.id >= 1) {
          apiUserConnected.getClientAllMissions(this.token, newObject.id)
          .then((result) => {
            if (result.data.length == 0) {
              this.missionsFilterSelectOptions = [{label: 'Toutes', value: 'false'}]
            }
            else if (result.data.length == 1) {
              this.missionsFilterSelectOptions = [{label: result.data[0].name, value: result.data[0].id}]
              this.missionFilter = result.data[0].id
            }
            else {
              var missions_filter_final_array = [{label: 'Toutes', value: 'false'}]
              if (this.$route.params.client_pk && this.$route.params.mission_pk) {
                missions_filter_final_array = []
              }
              for (var i = 0; i < result.data.length; i++) {
                // ----- If there is a route params

                if (this.$route.params.client_pk && this.$route.params.mission_pk) {
                  if (result.data[i].id == this.$route.params.mission_pk) {
                    missions_filter_final_array.push(
                      {
                        'label': result.data[i].name,
                        'value': result.data[i].id,
                      }
                    )
                  }
                }

                else {
                  missions_filter_final_array.push(
                    {
                      'label': result.data[i].name,
                      'value': result.data[i].id,
                    }
                  )
                }
              }
              this.missionsFilterSelectOptions = missions_filter_final_array
            }
          })
          .catch(() => {
            this.$store.commit('openGlobalErrorModal')
          })


          this.getAllTasksForManager()
        }
      }
      if (newObject.id == '') {
        this.getAllTasksForManager()
      }
    },
    collaborateurs: function (newCollaborateurs) {
      if (newCollaborateurs.length == 0) {
        this.collaborateursSelectOptions = []
        this.managerAlertCollaborateurSelectOptions = [{'label': '', 'value': ''}]
        this.collaborateursAlertMessageRender = []
      }
      else {
        var final_array = [{'label': 'Tous', 'value': 'false'}]
        var manager_alert_final_array = [{'label': '', 'value': ''}]
        var collaborateurs_alert_message_array = []
        for (var i = 0; i < newCollaborateurs.length; i++) {
          final_array.push(
            {
              'label': newCollaborateurs[i].full_name,
              'value': newCollaborateurs[i].id,
            }
          )

          manager_alert_final_array.push(
            {
              'label': newCollaborateurs[i].full_name,
              'value': newCollaborateurs[i].id,
            }
          )
          if (newCollaborateurs[i].manager_alert != '') {
            collaborateurs_alert_message_array.push(
              {
                'id': newCollaborateurs[i].id,
                'first_name': newCollaborateurs[i].user.first_name,
                'last_name': newCollaborateurs[i].user.last_name,
                'manager_alert': newCollaborateurs[i].manager_alert,
              }
            )
          }
        }
        this.collaborateursSelectOptions = final_array
        this.managerAlertCollaborateurSelectOptions = manager_alert_final_array
        this.collaborateursAlertMessageRender = collaborateurs_alert_message_array
      }
    },
    tasks: function (newTasks) {

      // TASK STATISTIQUES
      this.totalWorkingHours = 0
      this.totalNumberMissions = 0

      // TASK DETAILLED
      this.detailledTasksRender = []

      // TASK MISSION DOGHNUT
      var tasksMissionHoursRender = {}
      var tasksMissionMinutesRender = {}
      this.tasksDoghnutRenderMission = []
      this.tasksDoghnutRenderTime = [{
        borderWidth: 2,
        data: [],
        backgroundColor: []
      }]

      // TASK MISSION BAR
      var tempTasksBarChartMissionsRenderTime = {}
      this.tasksRenderDates = []
      this.tasksBarChartMissionsRenderTime = []

      // TASK COLLABORATEUR LINE
      var tempTasksLineChartCollaborateursRenderTime = {}
      this.tasksLineChartCollaborateursRenderTime = []

      // TASK COLLABORATEUR BAR
      var tempTasksBarChartCollaborateursRenderTime = {}
      this.tasksRenderCollaborateurs = []
      this.tasksBarChartCollaborateursRenderTime = []

      // Create Date table : entre startDateFilter and endDateFilter ou au max 30 jours avant endDateFilter
      var thirtyDaysBeforeEndDateFilter = this.$dayjs(this.endDateFilter).subtract(30, 'days')
      var tempDate = thirtyDaysBeforeEndDateFilter
      if (this.$dayjs(this.startDateFilter) >= thirtyDaysBeforeEndDateFilter) {
        tempDate = this.$dayjs(this.startDateFilter)
      }
      while (tempDate <= this.$dayjs(this.endDateFilter)) {
        this.tasksRenderDates.push(tempDate.format('YYYY-MM-DD'))
        tempDate = tempDate.add(1, 'days')
      }

      // Create Collaborateur table : TO OPTIMIZE pck on fait un FOR sur les taches ...
      // Calculate hours total
      // Populate detail render field
      for (var i = 0; i < newTasks.length; i++) {
        if (!this.tasksRenderCollaborateurs.includes(newTasks[i].collaborateur_full_name)) {
          this.tasksRenderCollaborateurs.push(newTasks[i].collaborateur_full_name)
        }
        this.totalWorkingHours = this.totalWorkingHours + newTasks[i].hours + Math.round(newTasks[i].minutes/60)
        this.detailledTasksRender.push(
          {
           'mission': newTasks[i].client_name + ' - ' + newTasks[i].mission_name,
           'collaborateur': newTasks[i].collaborateur_full_name,
           'date': newTasks[i].date,
           'heures': newTasks[i].hours,
           'minutes': newTasks[i].minutes,
           'description': newTasks[i].description,
           'hasBeenBilled': newTasks[i].has_been_billed
          }
        )
      }

      // Iterate through data
      for (var j = 0; j < newTasks.length; j++) {
        var customed_mission_name = newTasks[j].client_name + ' - ' + newTasks[j].mission_name
        // TASK MISSION DOGHNUT
        if (tasksMissionHoursRender[customed_mission_name] == undefined) {
          this.totalNumberMissions ++
          tasksMissionHoursRender[customed_mission_name] = newTasks[j].hours
          tasksMissionMinutesRender[customed_mission_name] = newTasks[j].minutes
        }
        else {
          tasksMissionHoursRender[customed_mission_name] += newTasks[j].hours
          tasksMissionMinutesRender[customed_mission_name] += newTasks[j].minutes
        }


        // TASK MISSION BAR
        if (tempTasksBarChartMissionsRenderTime[customed_mission_name] == undefined) {

          tempTasksBarChartMissionsRenderTime[customed_mission_name] = {}
          for (var k = 0; k < this.tasksRenderDates.length; k++) {
            tempTasksBarChartMissionsRenderTime[customed_mission_name][this.tasksRenderDates[k]] = 0
          }
          tempTasksBarChartMissionsRenderTime[customed_mission_name][newTasks[j].date] = newTasks[j].hours + Math.round(newTasks[j].minutes / 60)
        }
        else {
          tempTasksBarChartMissionsRenderTime[customed_mission_name][newTasks[j].date] += newTasks[j].hours + Math.round(newTasks[j].minutes / 60)
        }

        // TASK COLLABORATEUR LINE
        if (tempTasksLineChartCollaborateursRenderTime[newTasks[j].collaborateur_full_name] == undefined) {

          tempTasksLineChartCollaborateursRenderTime[newTasks[j].collaborateur_full_name] = {}
          for (var l = 0; l < this.tasksRenderDates.length; l++) {
            tempTasksLineChartCollaborateursRenderTime[newTasks[j].collaborateur_full_name][this.tasksRenderDates[l]] = 0
          }
          tempTasksLineChartCollaborateursRenderTime[newTasks[j].collaborateur_full_name][newTasks[j].date] = newTasks[j].hours + Math.round(newTasks[j].minutes / 60)
        }
        else {
          tempTasksLineChartCollaborateursRenderTime[newTasks[j].collaborateur_full_name][newTasks[j].date] += newTasks[j].hours + Math.round(newTasks[j].minutes / 60)
        }

        // TASK COLLABORATEUR BAR
        if (tempTasksBarChartCollaborateursRenderTime[customed_mission_name] == undefined) {

          tempTasksBarChartCollaborateursRenderTime[customed_mission_name] = {}
          for (var m = 0; m < this.tasksRenderCollaborateurs.length; m++) {
            tempTasksBarChartCollaborateursRenderTime[customed_mission_name][this.tasksRenderCollaborateurs[m]] = 0
          }
          tempTasksBarChartCollaborateursRenderTime[customed_mission_name][newTasks[j].collaborateur_full_name] = newTasks[j].hours + Math.round(newTasks[j].minutes / 60)
        }
        else {
          tempTasksBarChartCollaborateursRenderTime[customed_mission_name][newTasks[j].collaborateur_full_name] += newTasks[j].hours + Math.round(newTasks[j].minutes / 60)
        }
      }


      if (this.missionFilter == 'false') {
        // Create the final render variables for the mission dognhut
        var doughnutBackgroundColorIndice = 0
        for (var missionKey in tasksMissionHoursRender) {
          this.tasksDoghnutRenderMission.push(missionKey)
          this.tasksDoghnutRenderTime[0].data.push(tasksMissionHoursRender[missionKey] + Math.round(tasksMissionMinutesRender[missionKey] / 60))


          this.tasksDoghnutRenderTime[0].backgroundColor.push(chartBackgroundColorsArray[doughnutBackgroundColorIndice])
          doughnutBackgroundColorIndice ++
          if (doughnutBackgroundColorIndice > chartBackgroundColorsArray.length - 1) {
            doughnutBackgroundColorIndice = 0
          }
        }

        // Create the final render varialbes for the bar char
        var missionBarChartBackgroundColorIndice = 0
        for (var missionTempMission in tempTasksBarChartMissionsRenderTime) {
          this.tasksBarChartMissionsRenderTime.push({
            'label': missionTempMission,
            'data': Object.values(tempTasksBarChartMissionsRenderTime[missionTempMission]),
            'backgroundColor': chartBackgroundColorsArray[missionBarChartBackgroundColorIndice]
          })
          missionBarChartBackgroundColorIndice ++
          if (missionBarChartBackgroundColorIndice > chartBackgroundColorsArray.length - 1) {
            missionBarChartBackgroundColorIndice = 0
          }
        }
      }


      if (this.collaborateurFilter == 'false') {
        // Create the final render varialbes for the line chart collaborateur
        var collaborateurLineChartBackgroundColorIndice = 0
        for (var collabTempDate in tempTasksLineChartCollaborateursRenderTime) {
          this.tasksLineChartCollaborateursRenderTime.push({
            'label': collabTempDate,
            'data': Object.values(tempTasksLineChartCollaborateursRenderTime[collabTempDate]),
            'borderColor': chartBackgroundColorsArray[collaborateurLineChartBackgroundColorIndice],
            'fill': false,
          })
          collaborateurLineChartBackgroundColorIndice ++
          if (collaborateurLineChartBackgroundColorIndice > chartBackgroundColorsArray.length - 1) {
            collaborateurLineChartBackgroundColorIndice = 0
          }
        }

        // Create the final render variables for the bar char
        var collaborateurBarChartBackgroundColorIndice = 0
        for (var collabTempMission in tempTasksBarChartCollaborateursRenderTime) {
          this.tasksBarChartCollaborateursRenderTime.push({
            'label': collabTempMission,
            'data': Object.values(tempTasksBarChartCollaborateursRenderTime[collabTempMission]),
            'backgroundColor': chartBackgroundColorsArray[collaborateurBarChartBackgroundColorIndice]
          })
          collaborateurBarChartBackgroundColorIndice ++
          if (collaborateurBarChartBackgroundColorIndice > chartBackgroundColorsArray.length - 1) {
            collaborateurBarChartBackgroundColorIndice = 0
          }
        }
      }
    },

  },
  methods: {
    // -------------- GETTERS -----------------------
    getAllTasksForManager () {
      this.isAllManagerTasksDashboardLoading = true
      apiManagerConnected.getAllTasksForManager(this.token, this.startDateFilter, this.endDateFilter, this.clientFilterObject.id, this.missionFilter, this.collaborateurFilter)
      .then((result) => {
        this.tasks = result.data
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isAllManagerTasksDashboardLoading = false
      })
    },
    getAllCollaborateurs () {
      this.isAllCollaborateursLoading = true
      apiUserConnected.getAllCollaborateurs(this.token)
      .then((result) => {
        this.collaborateurs = result.data
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isAllCollaborateursLoading = false
      })
    },


    // ------------ Filter functions ----------------
    reinitializeWeek () {
      var today = this.$dayjs()
      this.endDateFilter = today.format('YYYY-MM-DD')
      this.startDateFilter = today.startOf('week').format('YYYY-MM-DD')
      this.getAllTasksForManager()
    },
    reinitializeMonth () {
      var today = this.$dayjs()
      this.endDateFilter = today.format('YYYY-MM-DD')
      this.startDateFilter = today.startOf('month').format('YYYY-MM-DD')
      this.getAllTasksForManager()
    },


    // ------------ SETTERS ------------------------
    setCollaborateurManagerAlert () {
      apiManagerConnected.setCollaborateurManagerAlert(this.token, this.managerAlertCollaborateur, this.managerAlertMessage)
      .then(() => {
        this.getAllCollaborateurs()
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isManagerAlertCreating = false
      })
    },
    removeCollaborateurManagerAlert (collaborateur_pk) {
      apiManagerConnected.removeCollaborateurManagerAlert(this.token, collaborateur_pk)
      .then(() => {
        this.getAllCollaborateurs()
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
    }
  }
}
</script>

<style>
.no-border-table td {
  border-top: None;
}

.thead-clickable {
  cursor: pointer;
}

.thead-clickable:hover{
  color: black !important;
}

</style>
